import React from 'react';
import axios from 'axios';
import Markdown from 'react-markdown'

export default class ISBN extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            cover: '',
            title: '',
            subtitle: '',
            description: '',
            author: '',
            textSnippet: '',
            publishedDate: '',
            pageCount: ''
          }
        this.getISBN = this.getISBN.bind(this);
      }
  

  getISBN(number) {
    console.log(number)
    var isbn = JSON.stringify(number)
    axios.get('https://www.googleapis.com/books/v1/volumes?q=ISBN:' + number)
      .then(res => {
        console.log(res.data)
        var cover = res.data.items[0].volumeInfo.imageLinks.thumbnail;
        var title = res.data.items[0].volumeInfo.title;
        var subtitle = res.data.items[0].volumeInfo.subtitle;
        var description = res.data.items[0].volumeInfo.description;
        var author = res.data.items[0].volumeInfo.authors[0];
        var publisher = res.data.items[0].volumeInfo.publisher;
        var textSnippet = res.data.items[0].searchInfo.textSnippet;
        var publishedDate = res.data.items[0].volumeInfo.publishedDate;
        var pageCount = res.data.items[0].volumeInfo.pageCount;
        this.setState({ cover, title, subtitle, description, author, publisher, textSnippet, publishedDate, pageCount });
      })
      .catch(err => console.log(err));
  }

  componentDidMount() {
    this.getISBN(this.props.number)
  }

  render() {
    return (
        <div style={{display: 'flex'}}>
            <img src={this.state.cover} style={{width: 'auto', height: '100%'}}></img>
            <ul style={{listStyle: 'none'}}>
                <li><b>Title:</b> {this.state.title}</li>
                {this.state.subtitle ? <li>Subtitle: {this.state.subtitle}</li> : null}
                <li><b>Author:</b> {this.state.author}</li>
                {this.state.description ? <li><Markdown source={this.state.description} escapeHtml={false} /></li> : null}
                {/* {this.state.description ? null : <li>Summary: <Markdown source={this.state.textSnippet} escapeHtml={false} /></li>} */}
                <li><b>Pages:</b> {this.state.pageCount}</li>
                <li><b>Published:</b> {this.state.publishedDate}</li>
                {this.state.publisher ? <li><b>Publisher:</b> {this.state.publisher}</li> : null}
            </ul>
        </div>
    )
  }
}