import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import Markdown from 'react-markdown'
import Img from 'gatsby-image'
import { Card, CardContent } from '@material-ui/core'
import Link from './link'
import styled from 'styled-components'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import ISBN from './isbn'

const Thumbnail = styled(Img)`
    > picture {
        overflow: hidden;
        :after {
            content: ' ';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0);
            transition: all 0.4s ease !important;
            pointer-events: none;
        }
        &:hover {
            :after {
                background: rgba(0,0,0,0.1);
            }
        }
        > img {
            transform: scale(1);
            transition: all 0.4s ease !important;
            &:hover {
                transform: scale(1.1);
                transition: all 0.4s ease !important;
            }
        }
    }
`

function projectPreview(edge) {
    const isbn = edge.node.frontmatter.isbn
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    return (
        // <Link className="projectCard" key={edge.node.id} to={edge.node.frontmatter.slug}>
        //     <div>
        //         <Thumbnail fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
        //         <h2 style={{fontWeight: '100'}}>{edge.node.frontmatter.title}</h2>
        //     </div>
        // </Link>
        <div className="projectCard" key={edge.node.id}>
            <div onClick={onOpenModal}>
                <Thumbnail fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
                <h2 style={{fontWeight: '100'}}>{edge.node.frontmatter.title}</h2>
            </div>
            <Modal open={open} onClose={onCloseModal} center>
                {/* <h1>Project details</h1>
                <hr/> */}
                <h2>{edge.node.frontmatter.title}</h2>
                <div>
                    <Img style={{width: 'auto', minWidth: '300px', height: 'auto', minHeight: '300px'}} fluid={edge.node.frontmatter.image.childImageSharp.fluid} />
                    <div>
                        {(edge.node.frontmatter.subtitle && edge.node.frontmatter.subtitle.length > 1) ? <p>{edge.node.frontmatter.subtitle}</p>: null}
                        {(edge.node.frontmatter.description && edge.node.frontmatter.description.length > 1) ? <Markdown source={edge.node.frontmatter.description} escapeHtml={false}/> : null}
                    </div>
                </div>
                {/* <ISBN number={isbn}/> */}
            </Modal>
        </div>
    )
}

export default projectPreview
